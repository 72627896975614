<template>
  <b-card>
    <b-row>

      <!-- Search assistance -->
      <b-col v-if="isOnline" sm="4">
        <small>{{ `${$t('message.search')} ${$t('message.problem_context')}` }}</small>
        <b-form-group>
          <b-form-input
            :placeholder="$t('message.search')"
            type="search"
            class="d-inline-block"
            v-model="searchQuery"
            @input="searchAssistance"
          />
        </b-form-group>
      </b-col>

      <!-- Date range -->
      <b-col sm="4">
        <small>{{ $t('message.tableHeader.dateRange') }}</small>
        <b-form-group label-for="v-date">
          <flat-pickr
            v-model="dateRange"
            class="form-control"
            :config="datePickerConfig"
            :placeholder="$t('placeholder.selectDateRange')"
          />
        </b-form-group>
      </b-col>

      <!-- Button to delete date filter -->
      <b-col sd="4" class="px-0 mt-1">
        <trash-button 
          @clicked="() => dateRange = ''" 
          v-b-tooltip.hover.v-warning.top="$t('clear_date _filter')">
        </trash-button>
      </b-col>

      <!-- List assistance -->
      <b-col cols="12">
        <b-table
          :empty-text="isLoading ? $t('message.loading') : $t('message.no_records')"
          show-empty=""
          :items="paginatedAssistances"
          hover
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
        >

          <template #cell(rootCause)="data">
            <div v-html="data.item.rootCause.length > 100 ? data.item.rootCause.substring(0, 100) + '...' : data.item.rootCause"></div>
          </template>

          <template #cell(actualState)="data">
            <div v-html="data.item.actualState.length > 100 ? data.item.actualState.substring(0, 100) + '...' : data.item.rootCause"></div>
          </template>

          <template #cell(actions)="data">
            <div class="flex flex-column"> 
              <ViewButton variant="flat-success" @clicked="handleAssistance(data.item._id)"/>
            </div>
          </template>
        </b-table>
      </b-col>
      
      <!-- Pagination -->
      <b-col
        v-if="isOnline && assistances.length > 0"
        cols="12"
        class="d-flex justify-content-between flex-wrap"
      >
        <div class="mb-1">
          <b-form-group class="mb-0" >
            <label class="d-inline-block text-sm-left mr-50">{{ $t('message.per_page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="[5,10,15,20,25,30,40,50,100]"
              class="w-50"
            />
          </b-form-group>
        </div>

        <div class="mb-1">
          <span class="text-muted">{{ showingMessage }}</span>
        </div>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalAssistance"
          :per-page="perPage"
          first-number=""
          last-number=""
          prev-class="prev-item"
          next-class="next-item"
        />
      </b-col>
    
    </b-row>
  </b-card>
</template>

<script>
import { BPagination, BTable  } from 'bootstrap-vue'
import useAssistance from './useAssistance'
import { onMounted, computed } from '@vue/composition-api'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import flatPickr from "vue-flatpickr-component"
import TrashButton from '@/views/components/Shared/Buttons/TrashButton'
import { VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    BTable,
    ViewButton,
    BPagination,
    flatPickr,
    TrashButton
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const {
      assistances,
      totalAssistance,
      isOnline,
      isLoading,
      showingMessage,
      searchQuery,
      tableColumns,
      sortBy,
      isSortDirDesc,
      perPage,
      currentPage,
      router,
      searchAssistance,
      getAssistancesData,
      handleAssistance,
      dateRange,
      datePickerConfig,
    } = useAssistance()

    onMounted(() => {
      getAssistancesData(dateRange.value)
    })

    const paginatedAssistances = computed(() => {
      const start = (currentPage.value - 1) * perPage.value
      const end = start + perPage.value
      return assistances.value.slice(start, end)
    })
    
    return {
      isOnline,
      searchQuery,
      searchAssistance,
      assistances,
      perPage,
      showingMessage,
      currentPage,
      paginatedAssistances,
      tableColumns,
      sortBy,
      isSortDirDesc,
      isLoading,
      router,
      totalAssistance,
      handleAssistance,
      dateRange,
      datePickerConfig,
    }
  },
}
</script>


<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>

