import store from '@/store'
import i18n from '@/libs/i18n'
import { ref, computed, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import useCommon from "@/views/organization/useCommon"
import realmConnection from '@/views/habit/realm'

export default function useAssistance() {

    const { router } = useRouter()
    const { handleAssistance, formatIsoDate } = useCommon()
    const { getItems, ObjectId } = realmConnection()
    const isOnline = computed(() => store.state.app.isOnline)
    const userData = store.state?.userStore?.userData;
    const clientId = userData.role !== "admin" ? userData.client.$oid : null
    const isLoading = ref(false) 
    const searchQuery = ref('')
    const perPage = ref(10)
    const currentPage = ref(1)
    const assistances = ref([])
    const totalAssistance = ref(0)
    const sortBy = ref('creationDate')
    const isSortDirDesc = ref(false)
    const { default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')

    // dateRage
    const now = new Date()
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1) // Get the first day of the current month
    const [y1, m1, d1] = firstDay.toISOString().substring(0, 10).split('-')
    const reverseFirstDay = `${d1}-${m1}-${y1}`
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0) // Get the last day of the current month
    const [y2, m2, d2] = lastDay.toISOString().substring(0, 10).split('-')
    const reverseLastDay = `${d2}-${m2}-${y2}`
    const dateRange = ref(`${reverseFirstDay} to ${reverseLastDay}`) // Get initial date range

    const tableColumns = computed(() => {
        return [
            { key: 'creationDate', label: i18n.t('message.tableHeader.creationDate') },  
            { key: 'problem', label: i18n.t('message.problem_context'), sortable: true },  
            { key: 'actualState', label: i18n.t('message.actual_state'), sortable: true },
            { key: 'rootCause', label: i18n.t('message.root_cause_analysis'), sortable: true },
            { key: 'actions', label: i18n.t('message.tableHeader.actions') },
        ]
    })

    const parseDate = (dateRange) => {
        const objDate = new Date(dateRange[2], dateRange[default_language === "en" ? 0 : 1] - 1, dateRange[default_language === "en" ? 1 : 0])
        const year = objDate.getFullYear()
        const month = objDate.getMonth()
        const day = objDate.getDate()
        const date = new Date(year, month, day)
        return date
    }

    const getAssistancesData = async (date, searchValue) => {
        if (searchValue && searchValue !== searchQuery.value) return
        isLoading.value = true

        const query = {
            client_id: ObjectId(clientId)
        }

        if (date) {
            // Date Range: by default it comes with 'to', but if you click on the same number for the from date and the to date, it arrives without 'to'
            let from, to
            if (date.includes(' to ')) {
                const datesArray = date.split(" ")
                from = datesArray[0].split("-")
                to = datesArray[2].split("-")
            }   else {
                from = to = date.split("-")
            }
            // Ensure the date is converted to the start and end of the day for accurate filtering
            const fromDate = new Date(parseDate(from))
            const toDate = new Date(parseDate(to))
            toDate.setHours(23, 59, 59, 999)

            query.creationDate = { $gte: fromDate, $lt: toDate }
        }
    
        try {
            const items = await getItems({ collection: 'assistance', query, options: { projection : { creationDate: 1, problem: 1, actualState: 1, rootCause: 1 }, sort: { creationDate: 1 } } })

            let backData = items

            if (searchQuery.value) {
                backData = backData?.filter(e => e.problem?.toLowerCase().includes(searchQuery.value.toLowerCase()))
            }

            assistances.value = await Promise.all(backData?.map(e => {
                e.creationDate = formatIsoDate(e.creationDate)
                return e
            }))
            totalAssistance.value = assistances.value.length
        } catch (error) {
            console.log(error)
        } finally {
            isLoading.value = false
        }
    }

    const searchAssistance = searchValue => {
        getAssistancesData(dateRange.value, searchValue)
    }

    const showingMessage = computed(() => {
        const total = totalAssistance.value
        const from = ((currentPage.value - 1) * perPage.value) + 1
        const to = Math.min(currentPage.value * perPage.value, total)
        return i18n.tc('message.paginationText', 0, { from, to, total })
    })

    const datePickerConfig = ref({
        mode: "range",
        dateFormat: default_language === "en" ? "m-d-Y" : "d-m-Y",
    })

    watch([dateRange], () => {
        getAssistancesData(dateRange.value)
    },{ deep: true })
    
    return {
        assistances,
        totalAssistance,
        isOnline,
        isLoading,
        showingMessage,
        searchQuery,
        tableColumns,
        sortBy,
        isSortDirDesc,
        perPage,
        currentPage,
        router,
        searchAssistance,
        getAssistancesData,
        handleAssistance,
        dateRange,
        datePickerConfig
    }

}